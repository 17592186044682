import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import FilterComponent2 from "../../../components/utils/FilterComponent2";
import axios from "../../../components/utils/Interceptors";
import { Card, Spin } from "antd";
import './ShippingMethod.css';

const getAuthHeaders = (token) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

// Partner Card Component
const PartnerCard = ({ partner, onToggle }) => {
  const selectLanguage = i18next.language;
  const shippingName =
    selectLanguage === "ar"
      ? partner.shipping_name_arabic
      : partner.shipping_name;
  const approxDeliveryTime =
    selectLanguage === "ar"
      ? partner.approx_delivery_time_arabic
      : partner.approx_delivery_time;

  return (
    <Card
      className="shadow-md rounded-md"
      cover={
        <img
          className="w-36 h-12 object-contain mx-auto mt-4"
          src={partner.shipping_logo}
          alt={shippingName}
        />
      }
    >
      <Card.Meta
        title={
          <h2 className="text-lg font-semibold text-center">{shippingName}</h2>
        }
        description={
          <div className="mt-4">
            <ul>
              <li>
                <span
                  title="Approximate Delivery Time"
                  className="cursor-pointer"
                >
                  <b>ADT:</b> {approxDeliveryTime}
                </span>
              </li>
            </ul>
            <div className="flex items-center gap-2 mt-4">
              <b>Enabled:</b>
              <input type="checkbox" onChange={(e) => onToggle(partner, Number(e.target.checked))} checked={partner?.is_enabled===1} className="toggle toggle-primary col-span-3 mt-2" />
            </div>
          </div>
        }
      />
    </Card>
  );
};

// Main Shipping Methods Component
const ShippingMethods = () => {
  const { t } = useTranslation();
  const selectLanguage = i18next.language;
  const user = JSON.parse(localStorage.getItem("user"));
  const [courierPartners, setCourierPartners] = useState([]);
  const [storeDetails, setStoreDetails] = useState([]);
  const [showShip, setShowShip] = useState(true);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");

  const fetchStoreDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`,
        { headers: getAuthHeaders(user.token) }
      );
      const store = res.data?.data;
      if (store) {
        setStoreDetails(store);
        setShowShip(
          store.torod_user_id &&
            store.torod_warehouse_id &&
            store.torod_shipping_enabled
        );
      }
    } catch (error) {
      toast.error("Error fetching store details.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCourierPartners = async () => {
    setLoading(true);
    try {
      if (showShip) {
        const res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/getallshippingsetting`,
          { headers: getAuthHeaders(user.token) }
        );
        setCourierPartners(res.data?.data || []);
      }
    } catch (error) {
      toast.error("Error fetching courier partners.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoreDetails();
  }, []);

  useEffect(() => {
    fetchCourierPartners();
  }, [showShip]);

  const handleTogglePartner = async (partner, isEnabled) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/createshippingsetting`,
        JSON.stringify({
          shipping_id: partner.shipping_id,
          is_enabled: isEnabled,
        }),
        { headers: getAuthHeaders(user.token) }
      );
      toast.success(res.data.message);
      fetchCourierPartners();
    } catch (error) {
      toast.error("Error updating partner settings.");
    } finally {
      setLoading(false);
    }
  };

  const createShippingAccount = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/createtoroduser`,
        { headers: getAuthHeaders(user.token) }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        toast.success(response.message);
        fetchStoreDetails();
      } else {
        const message =
          typeof response.message === "string"
            ? response.message
            : JSON.stringify(response.message);
        toast.error(message);
      }
    } catch (error) {
      toast.error("Error creating shipping account.");
    } finally {
      setLoading(false);
    }
  };

  const createShippingWarehouse = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/createtorodwarehouse`,
        { headers: getAuthHeaders(user.token) }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        toast.success(response.message);
        fetchStoreDetails();
      } else {
        const message =
          typeof response.message === "string"
            ? response.message
            : JSON.stringify(response.message);
        toast.error(message);
      }
    } catch (error) {
      toast.error("Error creating shipping warehouse.");
    } finally {
      setLoading(false);
    }
  };

  const enableShippingSetting = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/enabletorodshipping`,
        { headers: getAuthHeaders(user.token) }
      );
      toast.success(res.data.message);
      setShowShip(true);
    } catch (error) {
      toast.error("Error enabling shipping settings.");
    } finally {
      setLoading(false);
    }
  };

  let filteredPartners = courierPartners?.filter(
    (item) =>
      item.shipping_name?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.shipping_name_arabic?.includes(filterText)
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = (e) => {
      if (filterText) {
        setFilterText("");
        e.target.value = "";
      }
    };

    return (
      <div className="flex items-center">
        <FilterComponent2
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          setFilterText={setFilterText}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  return (
    <Spin spinning={loading} size="medium">
      <div>
        {showShip ? (
          <div>
            <div className="flex justify-between mb-5 mt-2">
              <h1 className="text-lg">
                {t("Available")} {t("Shipping_Methods")}
              </h1>
              {subHeaderComponent}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {filteredPartners.map((partner, i) => (
                <PartnerCard
                  partner={partner}
                  onToggle={handleTogglePartner}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>
            <h3 className="mb-4">
              {t("Shipping_Methods")} ({t("How It Works")}):{" "}
            </h3>

            <div className="p-4 border border-gray-200 bg-white shadow-sm ">
              <div className="flex justify-between mb-4 sm:my-4 w-full xl:w-9/12 flex-col sm:flex-row">
                <div className="my-4 sm:my-0">
                  <h4 className="text-xl font-bold">
                    {" "}
                    {selectLanguage == "ar"
                      ? "إنشاء مستودع"
                      : "Create Shipping Account"}
                  </h4>
                  <p>
                    {selectLanguage == "ar"
                      ? "تسجيل الدخول إلى حسابك الحالي لتمكين إعدادات الشحن"
                      : "Create your shipping account to enable shipping settings"}
                  </p>
                </div>

                <button
                  onClick={createShippingAccount}
                  disabled={storeDetails?.torod_user_id !== null}
                  className="btn-primary rounded-lg px-4 py-2 h-fit my-auto max-w-[13rem] font-semibold md:w-56 w-1/2 hover:text-white disabled:opacity-50"
                >
                  {selectLanguage == "ar"
                    ? "إنشاء مستودع"
                    : "Create Shipping Account"}
                </button>
              </div>

              <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
                <div className="my-4 sm:my-0">
                  <h4 className="text-xl font-bold">
                    {selectLanguage == "ar"
                      ? "إنشاء مستودع الشحن"
                      : "Create Shipping Warehouse"}
                  </h4>
                  <p className="w-11/12">
                    {selectLanguage == "ar"
                      ? "إنشاء مستودع الشحن إذا لم تقم بإنشائه حتى الآن. يرجى تجاهل هذه الخطوة إذا كنت قد قمت بإنشاء مستودع الشحن بالفعل."
                      : "Create your warehouse if you have not created till now. Please ignore this step if you have already created your warehouse"}
                  </p>
                </div>
                <button
                  onClick={createShippingWarehouse}
                  className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto max-w-[13rem] font-semibold md:w-56 w-1/2 hover:text-white disabled:opacity-50 ${
                    storeDetails.torod_warehouse_id
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  disabled={
                    storeDetails?.torod_user_id == null ||
                    storeDetails.torod_warehouse_id !== null
                  }
                >
                  {selectLanguage == "ar" ? "إنشاء مستودع" : "Create Warehouse"}
                </button>
              </div>

              <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
                <div className="my-4 sm:my-0">
                  <h4 className="text-xl font-bold">
                    {selectLanguage == "ar" ? "تمكين الشحن" : "Enable Shipping"}
                  </h4>
                  <p className="w-11/12">
                    {selectLanguage == "ar"
                      ? "لتمكين الشحن، انقر على الزر، تجاهل هذه الخطوة إذا تمت المهمة بالفعل"
                      : "To enable shipping click the button, ignore this step if already done"}
                  </p>
                </div>

                <button
                  onClick={enableShippingSetting}
                  className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto font-semibold max-w-[13rem] md:w-56 w-1/2 hover:text-white disabled:opacity-50 ${
                    storeDetails.torod_shipping_enabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  disabled={
                    storeDetails.torod_warehouse_id == null ||
                    storeDetails.torod_shipping_enabled !== null
                  }
                >
                  {selectLanguage == "ar"
                    ? "تمكين الشحن"
                    : "Enable Shipping Setting"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default ShippingMethods;
