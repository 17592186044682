import i18next from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../components/utils/Interceptors";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";

const AddPaymentMethods = () => {
  const navigate = useNavigate();
  const [methods, setMethods] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const getAllPaymentMethods = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallpaymentgateway`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        const res2 = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/getallpaymentsetting`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const response2 = await res2.data;
        let addedIds = [];
        if (response2.status_code === 200) {
          if (response2.data && response2.data.length > 0) {
            addedIds = response2.data.map((item) => item.payment_gateway_id);
          }
        }

        const gateways = response.data.map((item) => {
          if (addedIds.includes(item.id)) {
            return {
              ...item,
              added: true,
            };
          } else {
            return {
              ...item,
              added: false,
            };
          }
        });
        setMethods(gateways);
      }
    };

    getAllPaymentMethods();
    setLoading(false)
  }, []);

  const addPaymentGateway = async (id) => {
    setLoading(true)
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/createpaymentsetting`,
      JSON.stringify({ payment_gateway_id: id }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      const newgateways = methods.map((gateway) => {
        if (gateway.id === id) {
          return {
            ...gateway,
            added: true,
          };
        }
        return gateway;
      });
      setMethods(newgateways);
      toast.success("Gateway Added Successfully, Please Add Geteway Key");
      navigate("/vendor/settings/payment-methods");
    } else {
      toast.error(response.message);
    }
    setLoading(false)
  };

  const selectLanguage = i18next.language;

  return (
    loading?<LoadingSpinner/>:
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg">
          {selectLanguage === "ar"
            ? "إضافة طريقة دفع إلى المتجر"
            : "Add payment Gateway to your store"}
        </h1>

        <Link
          className="btn btn-sm rounded btn-primary text-white font-thin"
          to={"/vendor/settings/payment-methods"}
        >
          Back
        </Link>
      </div>

      <div className="my-5">
        {methods.length > 0 && (
          <>
            {methods.map((gateway) => (
              <div
                key={gateway.id}
                className="bg-white rounded p-4 flex justify-between mb-2 items-center"
              >
                <div className="gateway-info flex gap-2 items-center">
                  <img
                    src={gateway.gateway_image}
                    className="w-20 h-16 object-contain"
                    alt=""
                  />
                  <h3 className="text-lg font-medium">
                    {gateway.gateway_name}
                  </h3>
                </div>
                {gateway.added ? (
                  <span className="btn btn-sm rounded-none bg-danger text-white border-none btn-disabled">
                    Already Added
                  </span>
                ) : (
                  <span
                    onClick={() => addPaymentGateway(gateway.id)}
                    className="btn btn-sm rounded-none bg-secondary text-white border-none "
                  >
                    ADD{" "}
                  </span>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AddPaymentMethods;
