import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { Input, Form, Spin } from "antd";
import { useTranslation } from "react-i18next";

function AddressForm({ onSubmit, mode, email, phone, prevAddress = {} }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const [form] = Form.useForm();
  const addressRef = useRef();
  const { t } = useTranslation();
  const [searchAddress, setSearchAddress] = useState("");
  useEffect(() => {
    const sanitizedPhone = phone ? phone.toString().slice(-9) : "";
    form.setFieldsValue({
      ...prevAddress,
      email,
      phone: sanitizedPhone
    });
  }, [prevAddress, email, phone, form]);

  const handlePlaceSelect = () => {
    const place = addressRef.current.getPlace();
    if (place) {
      setSearchAddress(place.formatted_address);
      const city = place.address_components.find((c) =>
        c.types.includes("locality")
      );
      const region = place.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      );
      const district = place.address_components.find((c) =>
        c.types.includes("administrative_area_level_3")
      );
      const country = place.address_components.find((c) =>
        c.types.includes("country")
      );
      const zip = place.address_components.find((c) =>
        c.types.includes("postal_code")
      );
      
      form.setFieldsValue({
        address: place.vicinity,
        city: city?.long_name || "",
        region: region?.long_name || "",
        district: district?.long_name || "",
        country: country?.long_name || "",
        zip: zip?.long_name || "",
      });
    }
  };

  const onFinish = (values) => {
    const updatedValues = {
      ...values,
      phone: `966${values.phone}`,
      alternate_phone: mode === "customer" ? `966${values.alternate_phone}` : undefined
    };
    onSubmit(updatedValues);
  };

  return (
    <Spin spinning={!isLoaded}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label={t("Auto Fill By Google Map Address")} name="map_address">
          <Autocomplete
            onLoad={(autocomplete) => (addressRef.current = autocomplete)}
            onPlaceChanged={handlePlaceSelect}
          >
            <Input placeholder={t("Search address...")} value={searchAddress} onChange={(e)=>setSearchAddress(e.target.value)}/>
          </Autocomplete>
        </Form.Item>

        <Form.Item
          label={t("Street / Area / Locality ")}
          name="address"
          rules={[{ required: true, message: t("This field is required") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("Country")}
          name="country"
          rules={[{ required: true, message: t("This field is required") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("Region / State")}
          name="region"
          rules={[{ required: true, message: t("This field is required") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("City")}
          name="city"
          rules={[{ required: true, message: t("This field is required") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("District")}
          name="district"
          rules={[{ required: true, message: t("This field is required") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("Zip Code")}
          name="zip"
          rules={[
            { required: true, message: t("This field is required") },
            { pattern: /^[0-9]{4,6}$/, message: t("Invalid zip code") },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label={t("Email")}
          name="email"
          rules={[
            { type: "email", message: t("Invalid email address") },
            { required: true, message: t("This field is required") },
          ]}
        >
          <Input placeholder={t("Enter email")} />
        </Form.Item>

        <Form.Item
          label={t("Contact Number")}
          name="phone"
          rules={[
            { required: true, message: t("This field is required") },
            { pattern: /^[0-9]{9}$/, message: t("Invalid Contact number") },
          ]}
        >
          <Input
            addonBefore="+966"
            placeholder={t("Enter contact number Without Std Code")}
            type="tel"
          />
        </Form.Item>

        {mode === "customer" && (
          <Form.Item
            label={t("Alternate Contact Number")}
            name="alternate_phone"
            rules={[
              { pattern: /^[0-9]{9}$/, message: t("Invalid Contact number") },
            ]}
          >
            <Input
              addonBefore="+966"
              placeholder={t("Enter contact number Without Std Code")}
              type="tel"
            />
          </Form.Item>
        )}

        <Form.Item>
          <button
            type="submit"
            className="btn btn-sm rounded btn-primary text-white inline max-w-fit px-4"
          >
            {t("vd_pc_btn")}
          </button>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default AddressForm;
