import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../../../components/utils/Interceptors";
import AddressForm from "../../../../components/utils/AddressForm";
import { Spin } from "antd";

const LocationTab = () => {
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [storeDetails, setStoreDetails] = useState(null);
  const [prevAddress, setPrevAddress] = useState({});
  const { t } = useTranslation();

  const getStoreDetails = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const response = await res.data;

    if (response.data) {
      setStoreDetails(response.data);
      setPrevAddress({
        address: response.data?.store_address??'',
        city: response.data?.store_city_name??'',
        region: response.data?.store_region_name??'',
        country: response.data?.store_country_name??'',
        district: response.data?.store_district_name??'',
        zip: response.data.store_zip??'',
      })
    } else {
      return toast.info(response.message || response.err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStoreDetails();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const updatedData = {
      store_address : data.address,
      store_country_name : data.country,
      store_region_name : data.region,
      store_city_name : data.city,
      store_district_name : data.district,
      store_zip : data.zip,
      store_phone : data.phone,
      store_email : data.email,
      complete_address: `${data.address}, ${data.district}, ${data.city}, ${data.region}, ${data.country}, ${data.zip}`
    }
    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/updatestoredetails`,
      JSON.stringify(updatedData),
      {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${user ? user.token : ""}`,
        },
      }
    );
    let response = await res.data;

    if (response && response.status_code === 200) {
      getStoreDetails();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <div>
        <h5 className="mb-5">{t("Store Address")}</h5>
        <AddressForm
          onSubmit={(data) => {
            onSubmit(data);
          }}
          mode="vendor"
          email={storeDetails?.store_email || ""}
          phone={storeDetails?.store_phone || ""}
          prevAddress={prevAddress}
        />
      </div>
    </Spin>
  );
};

export default LocationTab;
